import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import success_icon from "../assets/notification_success_icon.svg";
import error_icon from "../assets/notification_error_icon.svg";
import info_icon from "../assets/notification_info_icon.svg";

const Notification = styled(ToastContainer)`
  ${({ theme }) => css`
    &.twiga-notifications {
      z-index: 10000;
      position: absolute;
      width: 40%;
      transform: translateX(0px);
      top: 0;
      right: 0;
      padding: 0;
    }
    .Toastify__toast-container {
    }
    .Toastify__toast {
      border-radius: 0;
      min-height: 0px;
      font-family: ${theme.fonts.body};
      margin-bottom: 2px;
      height: 65px;
      padding: 4px;
      margin: 20px 0;
    }
    .Toastify__toast--error {
      background: ${theme.colors.notifications.error};
      color: ${theme.colors.notifications.error_color};
      .Toastify__toast-body {
        p {
          &:before {
            background-image: url(${error_icon});
          }
        }
      }
    }
    .Toastify__toast--warning {
      background: ${theme.colors.notifications.info};
      color: ${theme.colors.notifications.info_color};
      .Toastify__toast-body {
        p {
          &:before {
            background-image: url(${info_icon});
          }
        }
      }
    }
    .Toastify__toast--success {
      background: ${theme.colors.notifications.green};
      color: ${theme.colors.notifications.green_color};
      .Toastify__toast-body {
        p {
          &:before {
            background-image: url(${success_icon});
          }
        }
      }
    }
    .Toastify__toast-body {
      font-weight: 600;
      margin: 0;
      padding: 10px 0;
      position: relative;
      text-align: center;
      p {
        margin: 0;
        padding: 10px 0;
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          left: -60px;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 60px;
          height: auto;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    .Toastify__progress-bar {
    }
    .Toastify__close-button {
      padding: 0 10px 0 0;
    }
  `}
`;

export default Notification;
