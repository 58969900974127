import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Flex, Spinner } from "theme-ui";
import Notification from "components/Toast";

const DMSLogin = lazy(() => import("./pages/dms"));
const SokoCallLogin = React.lazy(() => import("./pages/sokocall"));
const LMSLogin = React.lazy(() => import("./pages/lms"));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <Flex
            style={{
              width: "100%",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </Flex>
        }
      >
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/authorize" />
            </Route>
            <Route exact path="/authorize" component={DMSLogin} />
            <Route exact path="/authorize/sokocall" component={SokoCallLogin} />
            <Route exact path="/authorize/lms" component={LMSLogin} />
          </Switch>
        </Router>
      </Suspense>
      <Notification className="twiga-notifications" />
    </>
  );
}

export default App;
